<template>
  <div class="Category">
    <v-card class="mx-auto" max-width="1170">
      <v-toolbar color="orange" dark>
        <v-toolbar-title>ブランド・世代一覧</v-toolbar-title>
      </v-toolbar>
      <p>調べたい製品のブランド・世代を選択してください。</p>
      <v-list>
        <v-list-item-group>
          <v-list-item>
            <v-list-item-content>
              <v-list-item :to="{ name: 'Top' }">
                <v-list-item-content>トップに戻る</v-list-item-content>
              </v-list-item>
              <v-list-item :to="{ name: 'Category' }">
                <v-list-item-content>カテゴリ一覧に戻る</v-list-item-content>
              </v-list-item>
              <v-list-item :to="{ name: 'Manufacturer', params: { categorySlug: $route.params.categorySlug } }">
                <v-list-item-content>製造メーカー一覧に戻る</v-list-item-content>
              </v-list-item>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-card>
    <v-card class="mx-auto" max-width="1170">
      <v-toolbar color="orange" dark>
        <v-toolbar-title>ブランド・世代一覧</v-toolbar-title>
      </v-toolbar>
      <v-list two-line>
        <v-list-item-group>
          <template v-for="brand in brands">
            <v-list-item :key="brand.id" :to="{ name: 'ProductList', params: { categorySlug: $route.params.categorySlug, manufacturerSlug: $route.params.manufacturerSlug, brandSlug: brand.slug } }">
              <template>
                <v-list-item-content>
                  <v-list-item-title v-text="brand.name"></v-list-item-title>
                  <v-list-item-subtitle class="text--secondary" v-text="brand.productCount"></v-list-item-subtitle>
                  <v-list-item-subtitle v-text="brand.updated"></v-list-item-subtitle>
                </v-list-item-content>
              </template>
            </v-list-item>
          </template>
        </v-list-item-group>
      </v-list>
    </v-card>
  </div>
</template>


<script>
const axios = require('axios')

export default{
  name: 'Brand',
  components: {
  },
  data () {
    return {
      brands: null
    }
  },
  mounted() {
    const categorySlug = this.$route.params.categorySlug
    const manufacturerSlug = this.$route.params.manufacturerSlug
    axios.get('https://api.pc-combo.tokyo/categories/' + categorySlug + '/' + manufacturerSlug + '.json')
        .then(response => (this.brands = response.data.brands))
        .catch(error => console.log(error))
  }
}
</script>
